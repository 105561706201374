@import "./common/high-res-flags.css";

.react-tel-input .form-control {
  font-size: 16px;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  width: 300px;
  outline: none;
  padding: 18.5px 14px 18.5px 65px;
  transition: box-shadow ease .25s, border-color ease .25s;
}
.react-tel-input .form-control:hover {
  border-color: #000;
}
.react-tel-input .form-control:focus {
  border-color: #1976d2;
  box-shadow: 0 0 0 1px #1976d2;
}
.react-tel-input .form-control:focus+div:before {
  color: #1976d2;
}
.react-tel-input .form-control.invalid-number {
  border: 1px solid #f44336;
}
.react-tel-input .form-control.invalid-number:focus {
  box-shadow: 0 0 0 1px #f44336;
}
.react-tel-input .form-control.invalid-number+div:before {
  content: 'Error';
  display: none;
  color: #f44336;
  width: 27px;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown:focus {
  cursor: pointer;
}
.react-tel-input .flag-dropdown.open {
  z-index: 2;
}
.react-tel-input input[disabled]+.flag-dropdown:hover {
  cursor: default;
  border-color: #CACACA;
}
.react-tel-input input[disabled]+.flag-dropdown:hover .selected-flag {
  background-color: transparent;
}
.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 52px;
  height: 100%;
  padding: 0 0 0 11px;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .selected-flag:focus .arrow {
  border-left-width: 4px;
  border-right-width: 4px;
  border-top: 5px solid #1976d2;
}
.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -12px;
}
.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -1px;
  left: 29px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0,0,0,0.25);
  background-color: white;
  width: 300px;
  max-height: 220px;
  overflow-y: scroll;
  border-radius: 7px;
}
.react-tel-input .country-list .flag {
  display: inline-block;
  position: absolute;
  left: 13px;
  top: 8px;
}
.react-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
.react-tel-input .country-list .country {
  position: relative;
  padding: 12px 9px 13px 46px;
}
.react-tel-input .country-list .country .dial-code {
  color: #6b6b6b;
}
.react-tel-input .country-list .country:hover {
  background-color: #f1f1f1;
}
.react-tel-input .country-list .country.highlight {
  background-color: #f1f1f1;
}
.react-tel-input .country-list .flag {
  margin-right: 7px;
  margin-top: 2px;
}
.react-tel-input .country-list .country-name {
  margin-right: 6px;
}
.react-tel-input .country-list .search {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 10px 0 6px 10px;
}
.react-tel-input .country-list .search-emoji {
  display: none;
  font-size: 15px;
}
.react-tel-input .country-list .search-box {
  border: 1px solid #cacaca;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 3px 8px 5px;
  outline: none;
}
.react-tel-input .country-list .search-box:hover {
  border-color: #505050;
}
.react-tel-input .country-list .no-entries-message {
  padding: 7px 10px 11px;
  opacity: .7;
}
.react-tel-input .invalid-number-message {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  color: #de0000;
}
.react-tel-input .special-label {
  position: absolute;
  z-index: 1;
  top: -7px;
  left: 25px;
  display: block;
  background: white;
  padding: 0 5px;
  font-size: 13px;
  white-space: nowrap;
}

.react-tel-input .plus-sign {
  font-size: 18px;
  position: absolute;
  padding: 0;
  margin: 0;
  left: 54px;
  top: 50%;
  transform: translate(0, -50%);
  color: #999;
  pointer-events: none;
}